const url = window._env_.REACT_APP_API_AUTH_URL;

class UsersApi {
  async isUsernameAvailable(axiosPrivate, data, params) {
    const fetch = await axiosPrivate.get(
      `${url}/v1/users/username/${data}/isAvailable`,
      { params }
    );
    return fetch.data.payload;
  }

  async getCurrentUser(axiosPrivate) {
    const fetch = await axiosPrivate.get(`${url}/v1/users/current`);
    return fetch.data.payload;
  }
}

export default new UsersApi();
