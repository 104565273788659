import { DataStatus } from "components";
import { Formik } from "formik";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  successFetching,
  useAxiosPrivate,
  errorFetching,
  useAuth,
} from "utilities";
import { DetailSection, FormSection } from "./__LihatProfilComponents__";
import {
  formInitialValues,
  formValidationSchema,
} from "./__LihatProfilUtilities__";
import { StudentApi } from "api";
import LihatProfilApi from "./__LihatProfilApi__";

export const LihatProfil = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const permissions = auth.permissions;
  const [type, setType] = useState("Detail");
  const getDetail = useQuery(["detail-siswa"], () =>
    StudentApi.getDetailStudent(axiosPrivate)
  );
  const update = useMutation(
    ({ data, publicId }) => LihatProfilApi.update(axiosPrivate, data, publicId),
    {
      onSuccess: () => {
        setType("Detail");
        getDetail.refetch();
      },
    }
  );

  if (getDetail.isError || getDetail.isFetching) {
    return (
      <DataStatus
        loading={getDetail.isFetching}
        loadingText="Memuat data siswa..."
        text="Data siswa gagal dimuat"
      />
    );
  } else {
    if (type === "Detail") {
      return (
        <DetailSection
          permissions={permissions}
          data={getDetail.data}
          type={type}
          setType={setType}
        />
      );
    } else {
      return (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(
            getDetail.data,
            getDetail?.data?.user?.username
          )}
          validationSchema={formValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            const finalValues = {
              ...values,
              address: {
                ...values?.address,
                street: values?.street,
              },
            };

            update
              .mutateAsync({
                data: finalValues,
                publicId: getDetail.data.publicId,
              })
              .then((res) => {
                resetForm();
                successFetching(res);
              })
              .catch((err) => {
                errorFetching(err);
              });
          }}
        >
          {(formik) => {
            const { handleSubmit } = formik;
            return (
              <FormSection
                permissions={permissions}
                data={getDetail.data}
                type={type}
                setType={setType}
                onSubmit={handleSubmit}
                isSubmitting={update.isLoading}
              />
            );
          }}
        </Formik>
      );
    }
  }
};
