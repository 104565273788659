// <--- React --->
import { useState } from "react";

// <--- Third-party Library --->
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// <--- Form --->
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// <--- Function --->
import { useAuth } from "utilities";

// <--- Components --->
import { Alert, LoginButton } from "components";

// <--- API --->
import LoginApi from "./__LoginAPI__";

// <--- Config --->
import Config from "config";

// <--- CSS --->
import "styles/wave.css";
import { FaAngleLeft } from "react-icons/fa";

const Wave = () => {
  return (
    <div>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="0"
            fill="rgba(28,150,236,1)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="3"
            fill="rgba(28,150,236,0.5)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="5"
            fill="rgba(28,150,236,0.3)"
          />
          <use
            xlinkHref="#gentle-wave"
            x="48"
            y="7"
            fill="rgba(28,150,236,0.1)"
          />
        </g>
      </svg>
    </div>
  );
};

export const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: `(max-width: 769px)` });

  const from = location.state?.from?.pathname || "/";
  const package_json = require("../../../package.json");
  const version = package_json.version;
  const validationClassName = "validation-error-text";
  const permissions = ["STUDENT", "ALUMNI"];

  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [hideAlertMessage, setHideAlertMessage] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const formInitialValues = {
    username: "",
    password: "",
  };

  const formValidationSchema = Yup.object().shape({
    username: Yup.string().required("Username wajib diisi!"),
    password: Yup.string().required("Password wajib diisi!"),
  });

  const formSubmitHandler = async (values) => {
    setAuthenticationFailed(false);
    setHideAlertMessage(true);
    setSubmitting(true);

    let body = [];
    for (let property in values) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(values[property]);
      body.push(`${encodedKey}=${encodedValue}`);
    }
    body = body.join("&");

    await LoginApi.login(body)
      .then(
        (response) => {
          const accessToken = response?.data?.payload?.accessToken;
          const refreshToken = response?.data?.payload?.refreshToken;
          const resPermissions = response?.data?.payload?.permissions;

          if (permissions.some((val) => resPermissions.includes(val))) {
            localStorage.setItem("siswaku-id", refreshToken);
            setAuth({ accessToken, permissions: resPermissions });
            navigate(from, { replace: true });
          } else {
            setAuthenticationFailed(true);
            setHideAlertMessage(false);
            setAlertMessage("Anda tidak memiliki akses ke halaman ini.");
          }
        },
        (error) => {
          setAuthenticationFailed(true);
          setHideAlertMessage(false);
          error.response.data !== undefined
            ? setAlertMessage(error.response.data.errorMessage[0])
            : setAlertMessage("Terjadi kesalahan pada server.");
        }
      )
      .catch((error) => {
        setAuthenticationFailed(true);
        setHideAlertMessage(false);
        setAlertMessage(error);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <div
        style={{ minHeight: !isMobile && "calc(100% - 150px)" }}
        className={`${
          isMobile && "min-h-full"
        } flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8`}
      >
        <div className="max-w-md w-full space-y-8">
          <div>
            {/* <--- LOGO ---> */}
            <img
              className="mx-auto h-1/3 w-1/3 opacity-90"
              src={Config.LOGO}
              alt="Workflow"
            />
            {/* <--- NAMA APLIKASI ---> */}
            <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
              Aplikasi Siswaku
            </h2>
            {/* <--- VERSI ---> */}
            <p className="mt-2 text-center text-xs text-gray-600">
              ©2022 - Versi {version}
            </p>
          </div>
          {authenticationFailed && (
            <Alert
              message={alertMessage}
              messageType="error"
              hidden={hideAlertMessage}
              hideMessage={() => setHideAlertMessage(true)}
              hideCloseButton={false}
            />
          )}
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={(values) => {
              formSubmitHandler(values);
            }}
          >
            {(formik) => (
              <Form
                className="mt-8 space-y-6"
                method="post"
                onSubmit={formik.handleSubmit}
              >
                <div className="rounded-md shadow-sm -space-y-px">
                  {/* <--- USERNAME FIELD ---> */}
                  <div>
                    <label htmlFor="username" className="sr-only">
                      Username
                    </label>
                    <Field
                      id="username"
                      name="username"
                      type="text"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-clobasoft-medium-blue focus:border-clobasoft-medium-blue focus:z-10 sm:text-sm"
                      placeholder="Username"
                    />
                    {formik.errors.username &&
                    formik.touched.username &&
                    true ? (
                      <div>
                        <label className={validationClassName}>
                          {formik.errors.username}
                        </label>
                      </div>
                    ) : null}
                  </div>
                  {/* <--- PASSWORD FIELD ---> */}
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-clobasoft-medium-blue focus:border-clobasoft-medium-blue focus:z-10 sm:text-sm"
                      placeholder="Password"
                    />
                    {formik.errors.password &&
                    formik.touched.password &&
                    true ? (
                      <div>
                        <label className={validationClassName}>
                          {formik.errors.password}
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <--- BUTTON LOGIN ---> */}
                <div>
                  <LoginButton
                    isSubmitting={isSubmitting}
                    onClick={() => {
                      setAuthenticationFailed(false);
                      setHideAlertMessage(true);
                    }}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <div className="flex flex-row justify-center">
            <button
              className="text-sm transition duration-300 underline flex flex-row justify-center items-center hover:text-blue-600"
              onClick={() => navigate("/")}
            >
              <FaAngleLeft />
              <span>Kembali ke beranda</span>
            </button>
          </div>
        </div>
      </div>

      {!isMobile && <Wave />}
    </>
  );
};
